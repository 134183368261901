






























































































import { Vue, Component } from "vue-property-decorator";
import ExpressionOfInterest from "@/entity/ExpressionOfInterest";
import WebsiteApiService from "@/services/WebsiteApiService";
import { getFirstQueryValue } from "@/utils/queryParams";
import { formatPrice } from "@/utils/string";
import Link from "@/components/common/Link.vue";

enum Status {
  LOADING = "LOADING",
  READY = "READY",
  INVALID_TOKEN = "INVALID_TOKEN",
}

@Component({
  components: {
    Link,
  },
})
export default class ExpressionOfInterestPreview extends Vue {
  eoi: ExpressionOfInterest | null = null;
  currentStatus = Status.LOADING;

  get token() {
    return getFirstQueryValue(this.$route.query.token);
  }

  get showSkeleton() {
    return this.currentStatus === Status.LOADING;
  }

  get showTokenInvalid() {
    return this.currentStatus === Status.INVALID_TOKEN;
  }

  get eoiContent() {
    if (this.eoi === null) {
      return [];
    }

    return [
      {
        label: this.$tc("name", 1),
        value: `${this.eoi.firstName} ${this.eoi.name}`,
      },
      {
        label: this.$tc("eoiFormLabels.profileUrl", 1),
        value: this.eoi.profileUrl,
        link: true,
      },
      {
        label: this.$t("eoiFormLabels.interestAndIntention"),
        title: true,
      },
      {
        label: this.$tc("eoiFormLabels.interestedNote", 1),
        value: this.eoi.interestedNote,
      },
      {
        label: this.$tc("eoiFormLabels.intentionsNote", 1),
        value: this.eoi.intentionsNote,
      },
      {
        label: this.$t("eoiFormLabels.takeover"),
        title: true,
      },
      {
        label: this.$tc("eoiFormLabels.overtakeNote", 1),
        value: this.eoi.overtakeNote,
      },
      {
        label: this.$t("eoiFormLabels.roleOfSeller"),
        title: true,
      },
      {
        label: this.$tc("eoiFormLabels.sellerRoleNote", 1),
        value: this.eoi.sellerRoleNote,
      },
      {
        label: this.$t("financing"),
        title: true,
      },
      {
        label: this.$tc("eoiFormLabels.financingNote", 1),
        value: formatPrice(this.eoi.ownFunds, "CHF"),
      },
      {
        label: this.$tc("eoiFormLabels.approvedFinancingNote", 1),
        value: this.acquisitionExperienceOptions,
      },
    ];
  }

  get acquisitionExperienceOptions() {
    if (this.eoi === null) {
      return "";
    }

    switch (this.eoi.bankFinancedAcquisitionExperience) {
      case "EXPERIENCED": {
        return this.$t("acquisitionExperienceOptions.experienced").toString();
      }
      case "LIMITED_EXPERIENCE": {
        return this.$t(
          "acquisitionExperienceOptions.limitedExperience"
        ).toString();
      }
      case "NO_EXPERIENCE": {
        return this.$t("acquisitionExperienceOptions.noExperience").toString();
      }
      default: {
        return "";
      }
    }
  }

  async fetchEoi(): Promise<void> {
    if (this.token === undefined) {
      this.currentStatus = Status.INVALID_TOKEN;
      return;
    }

    try {
      this.currentStatus = Status.READY;
      this.eoi = await WebsiteApiService.getExpressionOfInterest(this.token);
    } catch (e) {
      this.currentStatus = Status.INVALID_TOKEN;
    }
  }

  canShowAsLink(value: null | string): boolean {
    return value !== null && value.length > 0;
  }

  created() {
    this.fetchEoi();
  }
}
